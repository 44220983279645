import React from 'react';

import { Divider, FormControlLabel, IconButton, MenuItem, Switch } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { styled } from 'styled-components';

import { StyledSelect, StyledTextField } from 'components/Input/styles';
import { possibleProtectorTypes } from 'containers/FieldManager/components/CreateEditFieldModal/types';
import { DisplayProtectorType, ProtectorType } from 'utils/api/types';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #0772e5;
  margin: 4px 0;
  background: #f5f5f5;
`;

const FieldProperties = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 8px;
  padding: 8px;
  justify-content: flex-end;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 8px;
  padding: 8px 24px;
`;

const EditField: React.FC<{
  description?: string;
  protectorType?: string;
  onChangeFieldDescription: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ description, protectorType, onChangeFieldDescription }): React.ReactElement => (
  <FieldContainer>
    <Row>
      <StyledTextField
        fullWidth
        size="small"
        style={{ backgroundColor: '#fff' }}
        defaultValue={description || ''}
        onChange={onChangeFieldDescription}
        placeholder="Description (Optional)"
      />
      <StyledSelect
        disabled
        displayEmpty
        fullWidth
        id="protectorType"
        name="protectorType"
        placeholder="Choose an Option"
        value={protectorType}
        $withoutPadding
        style={{
          height: 'auto',
          backgroundColor: '#fff',
          padding: '8.5px 14px',
        }}
      >
        <MenuItem value="" disabled>
          Choose an Option
        </MenuItem>
        {possibleProtectorTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {type === ProtectorType.SingleChoice ? 'Dropdown' : DisplayProtectorType[type]}
          </MenuItem>
        ))}
      </StyledSelect>
    </Row>
    <Row style={{ background: '#fff' }}>
      <StyledTextField
        placeholder="Placeholder for short text"
        fullWidth
        disabled
        size="small"
        style={{ backgroundColor: '#fff' }}
      />
    </Row>
    <FieldProperties>
      <FormControlLabel
        control={<Switch checked={false} disabled name="required" size="small" />}
        label={<p style={{ fontSize: '14px', margin: 0, padding: 0 }}>Required</p>}
      />
      <Divider orientation="vertical" flexItem />

      <IconButton size="small" disabled>
        <ContentCopyIcon />
      </IconButton>
      <IconButton size="small" disabled>
        <DeleteOutlineIcon />
      </IconButton>
    </FieldProperties>
  </FieldContainer>
);

export default EditField;
