/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

const isUUID = (key: string): boolean => {
  const uuidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
  return uuidRegex.test(key);
};

export const transformUUIDKeysToDescriptions = (data: any): any => {
  const transformedProperties: { [key: string]: any } = {};
  const transformedOrder: string[] = [];

  for (const key of data.order) {
    const property = data.properties[key];
    const newKey = isUUID(key) ? _.camelCase(property.description) : key;
    transformedProperties[newKey] = property;
    transformedOrder.push(newKey);
  }

  return {
    order: transformedOrder,
    properties: transformedProperties,
  };
};
