import React from 'react';

import { styled } from 'styled-components';

import { StyledTextField } from 'components/Input/styles';

const DisplayFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const DisplayField: React.FC<{
  description?: string;
}> = ({ description }): React.ReactElement => (
  <DisplayFieldContainer>
    <Title>{description || 'Untitled'}</Title>
    <StyledTextField fullWidth size="small" style={{ backgroundColor: '#fff' }} disabled />
  </DisplayFieldContainer>
);

export default DisplayField;
